<template>
  <div class="container">

    <PrimePreloader v-if="loading"/>

    <div v-else-if="isVisible" class="student-report mt-4 mb-4">
      <h4 style="text-align: center">Получение справки</h4>
      <h5 class="mt-4">Виды справок:</h5>
      <ol class="list-group list-group-numbered mt-4" >
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Справка обычная</div>
            Документ, подтверждающий факт обучения в UIB
          </div>
          <span class="badge bg-primary">
<!--            <a :href="'https://back.uib.kz/student/report/get-report?type=common&barcode='+getStudentBarcode" target="_blank" class="btn btn-primary m-1">-->
            <!--                <i class="far fa-file-pdf"></i>-->
            <!--              </a>-->
            <a @click="openModal('common')" class="btn btn-primary m-1">
              <i class="far fa-file-pdf"></i>
            </a>
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-5">
            <div class="fw-bold">Справка ГЦВП (для пособия)</div>
            Приложение 4 к Правилам исчисления (определения)
            размеров, назначения, осуществления, приостановления,
            перерасчета, возобновления, прекращения и пересмотра
            решения о назначении (отказе в назначении) социальной
            выплаты по случаю потери кормильца
          </div>
          <span class="badge bg-primary">
<!--            <a :href="'https://back.uib.kz/student/report/get-report?type=stipend&barcode='+getStudentBarcode" target="_blank" class="btn btn-primary m-1">-->
            <!--                <i class="far fa-file-pdf"></i>-->
            <!--              </a>-->
            <a @click="confirmReportToSendPrint(stipend_attachment_number_4_url)" class="btn btn-primary m-1">
              <i class="far fa-file-pdf"></i>
            </a>
          </span>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-5">
            <div class="fw-bold">Справка ГЦВП (для пособия)</div>
            Приложение 6
            к Правилам предоставления государственной
            базовой пенсионной выплаты за счет бюджетных средств, а также
            назначения и осуществления  пенсионных выплат по возрасту,
            государственных социальных пособий по инвалидности, по случаю потери кормильца, государственных специальных пособий
          </div>
          <span class="badge bg-primary">

            <a @click="confirmReportToSendPrint(stipend_attachment_number_6_1_url)" class="btn btn-primary m-1">
              <i class="far fa-file-pdf"></i>
            </a>
          </span>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-5">
            <div class="fw-bold">Справка ГЦВП (для пособия)</div>
            Приложение 31
            к Правилам назначения, исчисления (определения), перерасчета
            размеров социальных выплат из Государственного фонда социального страхования и их осуществления
          </div>
          <span class="badge bg-primary">

            <a @click="confirmReportToSendPrint(stipend_attachment_number_31_url)" class="btn btn-primary m-1">
              <i class="far fa-file-pdf"></i>
            </a>
          </span>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-5">
            <div class="fw-bold">Справка ГЦВП (для пособия)</div>
            Приложение 29 к Правилам назначения и
            осуществления выплаты государственного
            пособия на рождение, пособия по уходу,
            пособия многодетным семьям, пособия награжденной матери
          </div>
          <span class="badge bg-primary">

            <a @click="confirmReportToSendPrint(stipend_attachment_number_29_url)" class="btn btn-primary m-1">
              <i class="far fa-file-pdf"></i>
            </a>
          </span>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-5">
            <div class="fw-bold">Справка ГЦВП (для пособия)</div>
            Приложение 2 к Правилам исчисления (определения)
            размеров, назначения, выплаты, приостановления,
            перерасчета, возобновления, прекращения и
            пересмотра решения о назначении
            (отказе в назначении) государственного
            социального пособия по случаю потери кормильца
          </div>
          <span class="badge bg-primary">

            <a @click="confirmReportToSendPrint(stipend_attachment_number_2_url)" class="btn btn-primary m-1">
              <i class="far fa-file-pdf"></i>
            </a>
          </span>
        </li>


        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Справка Военкомат</div>
            Справка для военкомата
          </div>
          <span class="badge bg-primary">
<!--            <a :href="'https://back.uib.kz/student/report/get-report?type=rectal&barcode='+getStudentBarcode" target="_blank" class="btn btn-primary m-1">-->
            <!--                <i class="far fa-file-pdf"></i>-->
            <!--              </a>-->
            <a @click="confirmReportToSendPrint(rectalUrl)" class="btn btn-primary m-1">
              <i class="far fa-file-pdf"></i>
            </a>
          </span>
        </li>


        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Транскрипт</div>
            Оценки за весь период обучения
          </div>
          <span class="badge bg-primary">
            <a @click="confirmReportToSendPrintTranscript()" class="btn btn-primary m-1">
              <i class="far fa-file-pdf"></i>
            </a>
          </span>
        </li>
      </ol>


      <Toast />
      <ConfirmDialog></ConfirmDialog>
    </div>


    <Dialog v-model:visible="languageModalActive" header="Выберите язык"
            :breakpoints="{'560px': '90vw'}" :style="{width: '40vw'}">

      <div class="col-md-12 row m-0">
        <Dropdown v-model="selectedLanguage" :options="languages" optionDisabled="disabled"
                  optionLabel="name" optionValue="id" placeholder="Язык" class="col-12" />
      </div>

      <template #footer>
        <Button label="Выбрать" icon="pi pi-check" class="mb-2"
                :disabled="!selectedLanguage" @click="confirmReportToSendPrint(activeUrl)"/>
        <Button label="Отменить" icon="pi pi-times" class="p-button-danger mb-2" @click="() => {
          languageModalActive = false
          activeUrl = ''
        }"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import httpClient from "../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "StudentReport",
  data() {
    return {
      loading: true,
      isVisible: false,
      commonUrlRU: "https://back.uib.kz/student/report/get-report?type=common&barcode=",
      commonUrlKAZ: "https://back.uib.kz/student/report/get-report?type=commonkaz&barcode=",
      commonUrlENG: "https://back.uib.kz/student/report/get-report?type=commoneng&barcode=",

      transcriptUrlRU: "https://back.uib.kz/student/report/get-report?type=transcript&barcode=",
      transcriptUrlKAZ: "https://back.uib.kz/student/report/get-report?type=transcript&barcode=",
      transcriptUrlENG: "https://back.uib.kz/student/report/get-report?type=transcript&barcode=",

      stipend_attachment_number_4_url: "https://back.uib.kz/student/report/get-report?type=stipend&attachment=4&barcode=",
      stipend_attachment_number_31_url: "https://back.uib.kz/student/report/get-report?type=stipend&attachment=31&barcode=",
      stipend_attachment_number_6_1_url: "https://back.uib.kz/student/report/get-report?type=stipend&attachment=61&barcode=",
      stipend_attachment_number_29_url: "https://back.uib.kz/student/report/get-report?type=stipend&attachment=29&barcode=",
      stipend_attachment_number_2_url: "https://back.uib.kz/student/report/get-report?type=stipend&attachment=2&barcode=",
      rectalUrl: "https://back.uib.kz/student/report/get-report?type=rectal&barcode=",

      languageModalActive: false,
      selectedLanguage: null,
      languages: [{id: 137, name: 'Русский', disabled: false}, {id: 39, name: 'English', disabled: false}, {id: 82, name: 'Қазақ', disabled: true}],
      activeUrl: '',
      activeReportType: '',
    }
  },
  computed: {
    getStudentBarcode() {
      return getCookie('USERNAME')
    },
  },
  watch: {
    selectedLanguage(){
      switch (this.activeReportType){
        case 'common':
          switch (this.selectedLanguage){
            case 137:
              this.activeUrl = this.commonUrlRU
              console.log(this.activeUrl)
              break
            case 39:
              this.activeUrl = this.commonUrlENG
              console.log(this.activeUrl)
              break
            case 82:
              this.activeUrl = this.commonUrlKAZ
              console.log(this.activeUrl)
              break
          }
          break
        case 'transcript':
          switch (this.selectedLanguage){
            case 137:
              this.activeUrl = this.transcriptUrlRU
              break
            case 39:
              this.activeUrl = this.transcriptUrlENG
              break
            case 82:
              this.activeUrl = this.transcriptUrlKAZ
              break
          }
          break
      }
    }
  },
  methods: {
    ...mapActions('transcriptOrders', ['POST_TRANSCRIPT_ORDER']),

    confirmReportToSendPrintTranscript() {
      this.$confirm.require({
        message: 'Вы уверены что хотите отправить на печать?',
        header: 'Потверждение',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let res = await this.POST_TRANSCRIPT_ORDER()
          console.log('res', res)
          if (res === true) {
            this.$toast.add({severity:'info', summary:'Потвержден', detail:'Справка готовится в течение 3-х рабочих дней. Забрать справку можно в 104 кабинете.', life: 3000});
            this.languageModalActive = false
          } else {
            this.$toast.add({severity: 'error', summary: 'Ошибка', detail: res, life: 3000});
          }
        },
        reject: () => {

        }
      });
    },

    confirmReportToSendPrint(url) {
      console.log(url)
      this.$confirm.require({
        message: 'Вы уверены что хотите отправить на печать?',
        header: 'Потверждение',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.reportSendToPrint(url).then(res => {
            console.log(res, "RES")
            if (res == 1) {
              this.$toast.add({
                severity: 'info',
                summary: 'Потвержден',
                detail: 'Справка готовится в течение 3-х рабочих дней. Забрать справку можно в 104 кабинете.',
                life: 3000
              });
              this.languageModalActive = false
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Ошибка',
                detail: 'Печать запрещена. Обратитесь через день.',
                life: 3000
              });
            }
          });

        },
        reject: () => {

        }
      });
    },

    async reportSendToPrint(url) {
      try {
        const {
          status,
          data
        } = await httpClient.get('certificate/certificate/send-to-print?url=' + "%22" + url + this.getStudentBarcode + "%22" + '&access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          return data
        }

      } catch (e) {
        return null;
      }

    },
    async checkForReport() {
      try {
        const {status, data} = await httpClient.get('student_data/student-data/check-for-report')
        if (status === 200) {
          return data
        }
      } catch (e) {
        return false
      }
      return false
    },

    openModal(reportType) {
      this.activeReportType = reportType
      this.languageModalActive = true
    }
  },

  async mounted() {
    this.isVisible = await this.checkForReport()
    this.loading = false
  }
}
</script>

<style scoped>

</style>